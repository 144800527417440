@import 'themes';

@import '~@nebular/theme/styles/globals';

@import "../node_modules/angular-calendar/css/angular-calendar.css";


@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "Organetto-Regular";
  src: url("./assets/fonts/Organetto-Regular.ttf");
}

@font-face {
  font-family: "Organetto-Bold";
  src: url("./assets/fonts/Organetto-Bold.ttf");
}

@font-face {
  font-family: "Organetto-Ultra";
  src: url("./assets/fonts/Organetto-UltraBoldSemiExt.ttf");
}


$black: #1D1D1D;
$blue: #154BDC;
$blue-txt: white;
$black-secondary: #242323;
$gray: #C6C6C6;
$grey: rgba(143, 155, 179, 0.24);
$red-calendar: #ff8080;
$aqua: #4D9EB9;
$button: #5369a2;
$text: #707070;

html{
  scroll-behavior: smooth;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197);
    border-radius: 10px; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #154BDC; 
  }
}

nb-layout .layout{
  background-color: $black !important;
}

.font{
  font-family: 'Montserrat', sans-serif !important;
}

.font-regular{
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
}
//text color
.txt-black{
  color: $black;
}
.txt-blue{
  color: $blue-txt !important;
}
.txt-black-secondary{
  color: $black-secondary;
}
.txt-gray{
  color: $gray;
}

//backgrouds
.bg-black{
  background-color: $black;
}
.bg-blue{
  background-color: $blue;
}
.bg-black-secondary{
  background-color: $black-secondary;
}
.bg-gray{
  background-color: $gray;
}
.bg-black{
  background-color: $black;
}

.animated {
  visibility: visible !important;
}
.hide-on-init {
  visibility: hidden;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #fff !important;
}



//Calendar CSS
.welcome{
  // height: 520px;
  width: 100%; 
  padding: 1%; 
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number{
  color: #e8e8e8
  ;
}
.cal-month-view .cal-day-cell {
  min-height: 60px !important;
}
.cal-month-view{
  width: 600px;
  .cal-days{
    border: none !important;
  }
  .cal-day-cell{
    border: none !important;
    padding: 10px 0px !important;
  }
  .cal-cell-top{
    width: 50%;
    min-height: unset;
    margin: auto;
    border-radius: 10px;
    background-color: $grey;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cal-day-number{
    margin: 0;
    display: block;
    text-align: center;
    float: initial;
    opacity: 0.3;
    font-size: 1em !important;
  }
}

.owl-next,
.owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  height: 35px;
  width: 35px;
  background: transparent !important;
  border: solid 1px #5369a2;
  border-radius: 50% !important;
  display: inline-block;
}

.owl-next{
  right: 5%;
}
.owl-prev{
  left: 7%;
}

.owl-next i,
.owl-prev i {
  font-size: 1.5rem;
  text-align: center;
  color:  #5369a2;
}

.diabetic-carousel .owl-next i {
  font-size: 1.5rem;
  text-align: center;
  color:  #000000;
}

.diabetic-carousel .owl-next {
  background: #fff !important;
  right: 2% !important;
}

@media screen and (max-width:990px) {
    .cal-month-view{
      width: unset;
    }
}

.cal-cell-row{
  border: none !important;
  .cal-cell:hover{
    background-color: transparent !important;
  }
}

.cal-cell-row:hover{
  background-color: transparent !important;
}

.cal-day-cell.cal-out-month {
  .cal-cell-top{
    // background-color: $grey !important;
    display: none;
  }
}

.cal-has-events{
  .cal-cell-top{
    background-color: #fff !important;
    .cal-day-number{
      color: #000;
    } 
  }
  .cal-cell-top:hover{
    background-color:#154BDC !important;
    color: #fff !important;
    .cal-day-number{
      color: #fff !important;
    }
  }
  .cal-day-number{
    opacity: .8;
  }

}

.cal-today{
  background-color: transparent !important;
  .cal-day-number{
    font-size: 1em !important;
  }
}

.cal-events{
  display: none !important;
}

.cal-day-badge{
  display: none !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.8rem;
  outline: 0;
}


@media screen and (max-width: 480px){
   
  .cal-month-view .cal-cell-top {
      width: 80%;
  }

  .cal-month-view .cal-header .cal-cell{
    color: white;
    transform: translate(15px, 0px);
  }
  .cal-month-view .cal-header .cal-cell::first-letter {
    color: black;
    text-transform: capitalize;

  }
}


.cal-month-view {
  max-width: 100% !important;
  background-color: transparent;
}

.cal-month-view .cal-header{
  color: #e8e8e8;
}